@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;700&family=Noto+Sans:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-notosans;
    @apply w-full;
    @apply h-full;
  }
  body {
    @apply bg-slate-50;
    @apply w-full;
    @apply h-full;
  }
  #root {
    @apply h-full;
  }
}

:root {
  --bright-red: #fa2742;
  --bright-red-text: #e50624;
  --bright-red-bg: #e00623;
  --bright-red-hover: #af0623;
  --dark-slate: #373833;
  --light-slate: #f8fafc; /* slate-50 */
  --js-yellow: #f7df1e;
  --react-blue: #61dafb;
  --html-red: #e34f26;
  --css-blue: #1572b6;
  --git-red: #f05032;
  --reactrouter-red: #ca4245;
  --redux-purple: #764abc;
  --styledcomp-pink: #db7093;
  --tailwind-blue: #06b6d4;
  --c-gray: #a8b9cc;
  --mysql-blue: #4479a1;
  --figma-red: #f24e1e;
  --ts-blue: #3178c6;
  --jest-red: #c21325;
}
